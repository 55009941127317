<template>
  <div  class="px-15px px-lg-25px">
    <div class="card">
      <div class="card-header">
        <h1 class="h2 fs-16 mb-0">{{ $t('ding-dan-xiang-qing') }}</h1>
      </div>
      <div class="card-body">
        <div class="row gutters-3">
          <div class="col text-md-left text-center">
          </div>
          <div class="col-md-2 d-flex flex-nowrap justify-content-end align-items-end ml-auto" v-if="detailData.Status == 1">
              <button id="free_up_btn" type="button" class="btn btn-primary confirm-alert" @click="toPay">{{ $t('dai-fa-fu-kuan') }}</button>
          </div>

          <!-- <div class="col-md-3 ml-auto">
            <label for="update_payment_status">{{ $t('zhi-fu-zhuang-tai') }}</label>
            <input type="text" class="form-control" readonly v-model="detailData.payStatusName">
          </div> -->
          <div class="col-md-3 ml-auto">
              <label for="update_delivery_status">{{$t('you-ji-zhuang-tai')}}</label>
              <input type="text" class="form-control" readonly v-model="detailData.statusName">
          </div>
        </div>

        <div class="mb-3">

        </div>
        <div class="row gutters-5">
          <div class="col text-md-left text-center">
            <address>
              <div>{{$t('quan-ming')}}：{{detailData.ShippingAddress.FullName}}</div>
              <div>{{$t('dian-hua')}}：{{detailData.ShippingAddress.Phone}}</div>
              <div>{{$t('cheng-shi')}}：{{detailData.ShippingAddress.City}}</div>
              <div>{{$t('di-zhi')}}：{{detailData.ShippingAddress.Address}}</div>
            </address>
          </div>
          <div class="col-md-4 ml-auto">
            <table>
              <tbody>
                <tr>
                  <td class="text-main text-bold">{{ $t('ding-dan-hao-0') }}</td>
                  <td class="text-info text-bold text-right"> {{ detailData.OrderNo }}</td>
                </tr>
                <tr>
                  <td class="text-main text-bold">{{ $t('ding-dan-zhuang-tai') }}</td>
                  <td class="text-right">
<!--                    // 0: 待付款 1: 待发货 2: 待收货 3: 待评价 4: 已完成 5: 已取消 6: 已关闭 7: 退货中 8: 退款成功 9: 已评价-->
                    <span  class="badge badge-inline badge-info" v-if="detailData.Status == 0">{{ $t('dai-fu-kuan') }}</span>
                    <span  class="badge badge-inline badge-info" v-if="detailData.Status == 1">{{ $t('yi-ti-huo') }}</span>
                    <span  class="badge badge-inline badge-info" v-if="detailData.Status == 2">{{ $t('zai-tu-zhong') }}</span>
                    <span  class="badge badge-inline badge-success" v-if="detailData.Status == 3">{{ $t('yi-qian-shou') }}</span>
                    <span  class="badge badge-inline badge-danger" v-if="detailData.Status == 4">{{ $t('yi-wan-cheng') }}</span>
                    <span  class="badge badge-inline badge-danger" v-if="detailData.Status == 5">{{ $t('yi-qu-xiao') }}</span>
                    <span  class="badge badge-inline badge-danger" v-if="detailData.Status == 6">{{ $t('yi-guan-bi') }}</span>
                    <span  class="badge badge-inline badge-danger" v-if="detailData.Status == 7">{{ $t('tui-huo-zhong') }}</span>
                    <span  class="badge badge-inline badge-danger" v-if="detailData.Status == 8">{{ $t('tui-kuan-cheng-gong') }}</span>
                    <span  class="badge badge-inline badge-danger" v-if="detailData.Status == 9">{{ $t('yi-ping-jia') }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-main text-bold">{{ $t('ding-gou-ri-qi-0') }}</td>
                  <td class="text-right">{{detailData.CreatedAt}}</td>
                </tr>
                <tr>
                  <td class="text-main text-bold">{{ $t('zong-jin-e') }}</td>
                  <td class="text-right">
                    ${{ detailData.TotalAmount }}
                  </td>
                </tr>
                <tr>
                  <td class="text-main text-bold">{{ $t('Delivery-amount') }}</td>
                  <td class="text-right">
                    ${{ detailData.PickupAmount }}
                  </td>
                </tr>
                <tr>
                  <td class="text-main text-bold">{{ $t('fu-kuan-fang-shi') }}</td>
                  <td class="text-right">
                    {{detailData.PayType == 1 ? $t('huo-dao-fu-kuan') : (detailData.PayType == 2 ? 'USDT-TRC20' : $t('xian-xia-zhi-fu'))}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr class="new-section-sm bord-no">
        <div class="row">
          <div class="col-lg-12 table-responsive">
            <table class="table-bordered  invoice-summary table">
              <thead>
                <tr class="bg-trans-dark">
                  <th data-breakpoints="lg" class="min-col">#</th>
                  <th width="10%">{{ $t('zhao-pian') }}</th>
                  <th class="text-uppercase">{{ $t('shang-pin-ming-cheng-0') }}</th>
                  <th data-breakpoints="lg" class="text-uppercase">{{ $t('jiao-huo-lei-xing') }}</th>
                  <th data-breakpoints="lg" class="text-uppercase">{{ $t('bei-zhu') }}</th>
                  <th data-breakpoints="lg" class="min-col text-uppercase text-center">
                    {{ $t('shu-liang-0') }} </th>
                  <th data-breakpoints="lg" class="min-col text-uppercase text-center">
                    {{ $t('jia-ge') }} </th>
                  <th data-breakpoints="lg" class="min-col text-uppercase text-right">
                    {{ $t('zong-ji-0') }} </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in detailData.OrderProduct" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>
                      <img height="50" :src="item.BusinessProduct.MainImage">
                  </td>
                  <td>
                     {{item.BusinessProduct.ProductTranslation ? item.BusinessProduct.ProductTranslation[0].Name : ''}}
                  </td>
                  <td>
                    {{ $t('song-huo-shang-men') }} </td>
                  <td class="text-center">
                    {{item.Remark}}
                  </td>
                  <td class="text-center">{{ item.Number}}</td>
                  <td class="text-center">
                    ${{item.Price}}
                  </td>
                  <td class="text-center">${{ parseFloat(detailData.TotalAmount).toFixed(2)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="clearfix float-right">
          <table class="table">
            <tbody>
              <tr>
                <td>
                  <strong class="text-muted">{{ $t('ti-huo-jia-ge') }}</strong>
                </td>
                <td>
                  ${{ parseFloat(detailData.PickupAmount).toFixed(2)}}
                </td>
              </tr>
              <tr>
                <td>
                    <strong class="text-muted">{{ $t('li-run') }}</strong>
                </td>
                <td>
                  ${{ parseFloat(detailData.Income).toFixed(2)}}
                </td>
              </tr>
              <tr>
                <td>
                    <strong class="text-muted">{{ $t('xiao-ji-2') }}</strong>
                </td>
                <td>
                  ${{ parseFloat(detailData.TotalAmount).toFixed(2)}}
                </td>
                </tr>
                <tr>
                  <td>
                      <strong class="text-muted">{{ $t('shui-1') }}</strong>
                  </td>
                  <td>
                      $0.00
                  </td>
              </tr>
              <tr>
                <td>
                  <strong class="text-muted">{{ $t('yun-fei') }}</strong>
                </td>
                <td>
                  $0.00
                </td>
              </tr>
              <tr>
                <td>
                  <strong class="text-muted">{{ $t('you-hui-quan-0') }}</strong>
                </td>
                <td>
                  $0.00
                </td>
              </tr>
              <tr>
                <td>
                    <strong class="text-muted">{{ $t('zong-ji-1') }}</strong>
                </td>
                <td class="text-muted h5">
                  ${{ parseFloat(detailData.TotalAmount).toFixed(2)}}
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="no-print text-right">
            <a  type="button" class="btn btn-icon btn-light">
              <i class="las la-print"></i>
            </a>
          </div> -->
        </div>


        <div class="row gutters-5" id="wuliu">
          <div class="col-md-12 ml-auto" style="margin-top:10px;">
            <label for="update_tracking_code">{{ $t('orderDetail-1') }}</label>
            <div style="border:1px solid #f2f3f8; border-radius:10px;;padding:10px;margin-bottom:10px;" id="expessdiv">
              <form id="form1">
                <div class="dv">
                  {{ $t('orderDetail-2') }}：
                  <span>{{logistics.Company}}</span>
                </div>
                <div class="dv">
                  {{ $t('orderDetail-6') }}：
                  <span>{{logistics.ExecuteDays}} {{ $t('tian') }}</span>
                </div>
                <div class="dv" v-if="logisticsList.length > 0">
                  <br><br>
                  {{ $t('orderDetail-3') }}：<br><br>
                  <div class="exp" v-for="(item, i) in logisticsList" :key="i">
                    <div>{{ $t('orderDetail-4') }}：{{item.LogisticsRoute.Description}}</div>
                    <div>{{ $t('orderDetail-5') }}：{{item.ExecuteTime}}</div>
                    <br>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Popup v-model="showModal">
      <div class="popup-box " >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ $t('que-ren-fu-kuan') }}</h5>
              <button type="button" class="close" @click="cancel"></button>
            </div>
            <form class="" >
              <div class="modal-body gry-bg px-3 pt-3">

                <div class="row">
                  <div class="col-md-3">
                    <label>{{ $t('zi-jin-mi-ma') }} <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-9">
                     <input type="password"  class="form-control mb-3" v-model="password" :placeholder="$t('jin-e')" required>
                  </div>
                </div>

                <div class="form-group text-right">
                  <button type="button" class="btn btn-sm btn-primary transition-3d-hover mr-1" @click="doPay">{{ $t('que-ding') }}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import pageBar from '@/components/page'
import {shopOrderList, orderPay, cronList} from '@/api/seller'
import { resetPrice } from '@/utils/common'
import { Popup } from 'vant'
import moment from "moment";
export default {
  components: {
    pageBar,
    Popup
  },
  data() {
    return {
      showModal: false,
      password: '',
      detailData: {
        ShippingAddress: {},
        OrderProduct: []
      },
      statusList: [
        {
          value: 1,
          label: this.$t('wei-fa-huo')
        },
        {
          value: 2,
          label: this.$t('fa-huo-zhong')
        },
        {
          value: 3,
          label: this.$t('yi-shou-huo')
        },
      ],
      dataId: '',
      logistics: {},
      logisticsList: []
    }
  },
  mounted() {
    this.dataId = this.$route.query.id
    this.init()
  },
  methods: {
    resetPrice,
    init() {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 10)
      form.append('MerchantPayStatus', '')
      form.append('ID', this.dataId)
      shopOrderList(form).then(res => {
        let data = res.data.Items
        if (data.length > 0) {
          this.detailData = data[0]
          this.detailData.payStatusName = this.getPayStatusName(this.detailData.PayStatus)
          this.detailData.statusName = this.getStatusName(this.detailData.Status)
          let form = new FormData()
          form.append('OrderId', this.dataId)
          cronList(form).then(res =>{
            let data = res.data.Items
            if (data.length > 0) {
              let logisticId = this.detailData.ShowRoute ? JSON.parse(this.detailData.ShowRoute) : []
              // console.log("logisticId",logisticId)
              data.forEach(v => {
                console.log("v",v)
                if (logisticId instanceof Array) {
                  if (logisticId.indexOf(v.LogisticsRouteId) > -1) {
                    this.logisticsList.push(v)
                  }
                } else {
                  if (logisticId[v.LogisticsRouteId]) {
                    this.logisticsList.push(v)
                  }
                }
              })
              // console.log("this.logisticsList",this.logisticsList)
              // this.logisticsList = data[0].LogisticsRoute
            }

          })
          //物流信息
          let logisticId = this.detailData.ShowRoute ? JSON.parse(this.detailData.ShowRoute) : []
          this.logistics = this.detailData.Logistics
          // this.logisticsList = this.detailData.Logistics ? this.detailData.Logistics.LogisticsRoute.filter(v => {
          //   if (logisticId instanceof Array) {
          //     return logisticId.indexOf(v.ID) > -1
          //   } else {
          //     return logisticId[v.ID]
          //   }
          // }) : []
        }
      })

    },
    toPay() {
      // this.showModal = true
      this.doPay()
    },
    doPay() {
      let form = new FormData()
      form.append('ID', this.detailData.ID)
      form.append('Password', '')
      orderPay(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('fu-kuan-cheng-gong'))
          this.init()
          this.showModal = false
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    getPayStatusName(status) {
      return status == 1 ?  this.$t('yi-zhi-fu') : this.$t('wei-zhi-fu')
    },
    getStatusName(status) {
      let name = ''
      // 0: 待付款 1: 待发货 2: 待收货 3: 待评价 4: 已完成 5: 已取消 6: 已关闭 7: 退货中 8: 退款成功 9: 已评价
      switch(status + '') {
        case '0': name = this.$t('dai-fu-kuan'); break;
        case '1': name = this.$t('yi-ti-huo'); break;
        case '2': name = this.$t('zai-tu-zhong'); break;
        case '3': name = this.$t('yi-qian-shou'); break;
        case '4': name = this.$t('yi-wan-cheng'); break;
        case '5': name = this.$t('yi-qu-xiao'); break;
        case '6': name = this.$t('yi-guan-bi'); break;
        case '7': name = this.$t('tui-huo-zhong'); break;
        case '8': name = this.$t('tui-kuan-cheng-gong'); break;
        case '9': name = this.$t('yi-ping-jia'); break;
      }
      return name
    },
    ExecuteTime(time, day) {
      return moment(time).add(day, 'days').format('YYYY-MM-DD HH:mm:ss')
    },
    cancel() {
      this.showModal = false
    }
  }
}
</script>